import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {GridRowClickEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import {CustomButton, GridField} from "ndr-designsystem";
import GridDataState from "../../../api/fixed/GridDateState"
import {setTenants} from "../store/TenantsSlice"
import {useAppDispatch, useAppSelector} from "../../../app/hooks"
import {fetchTenantListByGridDataState} from "../store/Thunks"
import {setCurrentPage} from "../../appStateSlice";

const TenantsList: React.FC<{}> = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('tabels');
    const [dataState, setDataState] = useState<GridDataState | undefined>(undefined);
    const { tenants } = useAppSelector(state => state.Tenants);

    useEffect(() => {
        dispatch(setCurrentPage("tenants_list"))
    }, [t, dispatch])

    useEffect(() => {
        if (dataState)
            dispatch(fetchTenantListByGridDataState(dataState));
    }, [dataState, dispatch])

    const forceRefresh = (): void => {
        dispatch(setTenants(undefined))
    }

    const onRowClick = (e: GridRowClickEvent): void => {
        history.push(`/tenants/${e.dataItem.inventoryItemId}`);
    }
    const handleAddTenant = (): void => {
        history.push(`/tenants/add`);
    }

    const onDataChange = (newState: GridDataState): void => {
        if (newState.skip !== dataState?.skip) {
            setDataState(newState);
        }
    }

    const CustomToolbar = (): ReactElement => {
        return (
            <div>
                <CustomButton variant="contained" title={t('tenantsList.add_new')} color="primary" onClick={handleAddTenant} />
            </div>
        );
    };

    const gridFields = useMemo(() => {
        return [
            {
                title: "ID",
                name: "inventoryItemId"
            },
            {
                title: t('tenantsList.name'),
                name: "name"
            },
            {
                title: t('tenantsList.marketPartnerIDCode'),
                name: "marketPartnerIDCode"
            },
            {
                title: t('tenantsList.marketPartnerIDEncoding'),
                name: "marketPartnerIDEncoding"
            },
            {
                title: t('tenantsList.NumberOfControllableResources'),
                name: "controllableResourcesCount"
            },
            {
                title: t('tenantsList.NumberOfReceivedActivations'),
                name: "activationsCount"
            },
            {
                title: t('tenantsList.NumberOfUsers'),
                name: "users.length"
            }
        ]
    }, [])

    return (
        <div>
            <GridField
                customToolbar={<CustomToolbar />}
                addButtonText="true"
                totalItems={tenants?.length}
                values={tenants}
                noRecordsText={t('tabels:no_records_text')}
                gridFields={gridFields}
                onRefresh={forceRefresh}
                onAddNew={handleAddTenant}
                onDataStateChange={onDataChange}
                onRowClick={onRowClick}
                dataItemKey="inventoryItemId"
                defaultOpen
            />
        </div>
    )
}


export default TenantsList
