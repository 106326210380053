/* eslint-disable react/jsx-props-no-spreading */
import React, {ReactElement} from 'react'
import {Box, Button, Grid, IconButton, Paper, TextField} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EN from "../../Assets/Icons/EN-square.png";
import GR from "../../Assets/Icons/GR-square.png";
import useStyles from './ForgetPasswordStyle';


interface IForgotForm {
    email: string
}

const ForgetPassword = (): ReactElement => {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();

    const { register, handleSubmit } = useForm<IForgotForm>();

    const HandleLangEnglish = (): void => {
        i18n.changeLanguage("en");
    }
    const HandleLangGermany = (): void => {
        i18n.changeLanguage("de");
    }

    const onSubmit: SubmitHandler<IForgotForm> = async (data) => {
        // eslint-disable-next-line no-console
        console.log(data)
    }
    return (
        <div>
            <Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Paper elevation={10} className={classes.paperDesign} >
                        <Box>
                            <h3>{t("Forgot.forgot_pw")}</h3>
                        </Box>
                        <TextField label={t("Forgot.email")} placeholder={t("Forgot.enterEmail")} className={classes.forgotInputs} fullWidth required {...register("email")} />
                        <Box mt={20} >
                            <Button type="submit" color="primary" className={classes.newPasswordButton} fullWidth variant="contained">{t("Forgot.GetNewPassword")}</Button>
                        </Box>
                        <Box mt={2}><NavLink to="/login"><div className={classes.LoginPageButton}>Login Page</div></NavLink></Box>
                        <Box pt={7}>
                            <IconButton onClick={HandleLangEnglish}><img src={EN} alt="English" />  </IconButton>|<IconButton onClick={HandleLangGermany}><img src={GR} alt="Germany" /></IconButton>
                        </Box>
                    </Paper>
                </form>
            </Grid>
        </div >
    )
}


export default ForgetPassword