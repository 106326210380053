import React, {ReactElement} from "react";
import {useAppSelector} from "../../app/hooks";

interface Props {
    match: { path: string }
}

const TenantBreadcrumb = ({ match }: Props): ReactElement => {
    const {currentTenant} = useAppSelector(state => state.Tenants)

    return <>{currentTenant?.name && currentTenant?.name.length > 0 ? currentTenant?.name : "Add"}</>
}

export default TenantBreadcrumb
