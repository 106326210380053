import React, {ReactElement} from "react";
import {Redirect, Route} from "react-router-dom";

interface ProtectedRoutesProps {
    isAuth: boolean;
    Component: any;
    path: string;
}
interface RouterRenderProps {
    location: any
}

const ProtectedRoutes = ({ isAuth, Component, path }: ProtectedRoutesProps): ReactElement => (

    <Route path={path} render={(props: RouterRenderProps) => {
        if (isAuth) {
            return <Component />
        }
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }}
    />
)

export default ProtectedRoutes;