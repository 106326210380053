import * as yup from 'yup';
import { useTranslation } from "react-i18next";

export default function AdminUsersValidation(isResetPW: boolean): any {
    const { t } = useTranslation('validation');

    const schema = yup.object().shape(
        isResetPW ? {
            password: yup
                .string()
                .required()
        } : {
            email: yup
                .string()
                .required(t('addAdmin.RequiredEmail'))
                .email(t('addAdmin.InvalidEmail')),

            name: yup
                .string()
                .required(t('addAdmin.RequiredUserName'))
                .min(3, t('addAdmin.MinUserName'))
                .max(15, t('addAdmin.MaxUserName')),

            phone: yup
                .string()
                .max(15, t('addAdmin.MaxPhone')),

        });

    return schema
}


