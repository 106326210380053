import React, {ReactElement, useEffect} from "react";
import {Grid} from "@mui/material";
import TenantsCard from "../Cards/TenantsCard";
import AdminsCard from "../Cards/AdminsCard";
import {setCurrentPage} from "../../features/appStateSlice";
import {useAppDispatch} from "../../app/hooks";

const HomeContent = (): ReactElement => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("home"))
    }, [dispatch])

    return <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={10}
          sx={{ marginTop: 1 }}>
        <Grid item md={6}><TenantsCard /></Grid>
        <Grid item md={6}><AdminsCard /></Grid>
        <Grid item md={3} />
        <Grid item md={3}/>
        <Grid item md={3} />
        <Grid item md={9}/>
    </Grid>
}

export default HomeContent