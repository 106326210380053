import {Field, isFieldValid, PageStatus} from "ndr-designsystem";
import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import SchemaConstants from "../../../utils/schemaConstants";
import {setMail, setMainContactName, setPhone} from "../store/TenantsSlice";
import {MainContactSchema} from "../../../validations/TenantDetailsValidation";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

const MainContact = ({pageStatus}: {pageStatus: PageStatus}): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT
    const isAdding = pageStatus === PageStatus.ADD
    const mainContactSchema = MainContactSchema();
    const dispatch = useAppDispatch()
    const { currentTenant: tenant} = useAppSelector(state => state.Tenants)
    const {t} = useTranslation("tabels")

    return (
        <>
            <Field
                inputType="text"
                isEditing={isEditing || isAdding}
                valueCheckFunction={name => isFieldValid(mainContactSchema, [SchemaConstants.name], { name })}
                onValueChange={(name) => dispatch(setMainContactName(name))}
                defaultValue={tenant?.mainContact?.name}
                title={t("tenantDetails.name")}
            />
            <Field
                inputType="text"
                isEditing={isEditing || isAdding}
                valueCheckFunction={phone => isFieldValid(mainContactSchema, [SchemaConstants.phone], { phone })}
                onValueChange={(phone) => dispatch(setPhone(phone))}
                defaultValue={tenant?.mainContact?.phone}
                title={t("tenantDetails.phone")}
            />
            <Field
                inputType="text"
                isEditing={isEditing || isAdding}
                valueCheckFunction={email => isFieldValid(mainContactSchema, [SchemaConstants.email], { email })}
                onValueChange={(email) => dispatch(setMail(email))}
                defaultValue={tenant?.mainContact?.email}
                title={t("tenantDetails.email")}
            />
        </>
    )
}

export default MainContact