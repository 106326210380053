import React, {ReactElement, useEffect, useState} from 'react'
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridDataStateChangeEvent,
    GridNoRecords,
    GridToolbar
} from "@progress/kendo-react-grid";
import {process} from "@progress/kendo-data-query";
import {useTranslation} from "react-i18next";
import {Box, IconButton} from '@mui/material';
import {Cached} from "@mui/icons-material";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {CustomButton, GridFieldActionsCell, LoadingComponent} from "ndr-designsystem"
import GridDataState from "../../api/fixed/GridDateState";
import {useAppDispatch, useAppSelector} from "../../app/hooks"
import {AddAdminUser, DeleteAdminUser, EditAdminUser, fetchAdminUsers, ResetAdminUserPass} from "./store/Thunks"
import {setAdmins} from './store/AdminsSlice';
import AdminUsersValidation from '../../validations/AdminUsersValidation';
import Admin from '../../api/fixed/Admin';
import useStyles from './AdminsListStyle';
import {setCurrentPage} from "../appStateSlice";
import AddDialog from "../../Components/AddDialog/AddDialog";

const AdminsList = (): ReactElement => {
    const classes = useStyles()
    const [currentAdmin, setCurrentAdmin] = useState<Admin | undefined>(undefined);
    const [currentlyEditing, setCurrentlyEditing] = useState<"add" | "edit" | "reset" | undefined>()
    const { admins } = useAppSelector(state => state.Admins);
    const { t } = useTranslation(['tabels', 'common']);
    const dispatch = useAppDispatch();
    const AdminUsersValidationSchema = currentlyEditing === "reset" ? AdminUsersValidation(true) : AdminUsersValidation(false)
    const { register, reset, formState, handleSubmit: HandleAdminDialogSubmit, setValue }
        = useForm<any>({
            mode: 'onTouched',
            resolver: yupResolver(AdminUsersValidationSchema),
        });
    const [dataState, setDataState] = useState<GridDataState>({
        skip: 0,
        take: 10,
        filter: undefined,
        sort: []
    });

    useEffect(() => {
        dispatch(fetchAdminUsers())
        dispatch(setCurrentPage("admins_list"))

        return () => {
            dispatch(setAdmins(undefined))
        }
    }, [dispatch, t])

    const forceRefresh = (): void => {
        dispatch(setAdmins(undefined))
        dispatch(fetchAdminUsers())
    }

    const onDataChange = (changeEvent: GridDataStateChangeEvent): void => {
        setDataState(changeEvent.dataState);
    }

    const onAdminDialogSubmit: SubmitHandler<any> = async (data) => {
        switch (currentlyEditing) {
            case "add": await dispatch(AddAdminUser(data)); break;
            case "edit": {
                const { password, ...rest } = data
                await dispatch(EditAdminUser({ ...rest, inventoryItemId: currentAdmin?.inventoryItemId, revision: currentAdmin?.revision }))
            } break;
            case "reset": await dispatch(ResetAdminUserPass({ UserID: currentAdmin?.inventoryItemId ?? "", pass: data.password })); break;
            default: break;
        }
        setCurrentAdmin(undefined);
        reset();
    }

    const onCurrentAdminClose = (): void => {
        setCurrentAdmin(undefined)
        reset()
    }

    const handleEdit = (adminIndex: number): void => {
        const newAdmin = (admins ?? [])[adminIndex]
        setCurrentAdmin(newAdmin);
        setCurrentlyEditing("edit")
        setValue("name", newAdmin.name);
        setValue("email", newAdmin.email);
        setValue("phone", newAdmin.phone);
    }

    const handleRemove = (adminIndex: number): void => {
        dispatch(DeleteAdminUser((admins ?? [])[adminIndex]))
    }

    const handleReset = (adminIndex: number): void => {
        const newAdmin = (admins ?? [])[adminIndex]
        setCurrentlyEditing("reset")
        setCurrentAdmin(newAdmin);
        setValue("name", newAdmin.name);
        setValue("email", newAdmin.email);
        setValue("phone", newAdmin.phone);
    }

    const CommandsCell = (props: GridCellProps): ReactElement =>
        <GridFieldActionsCell {...props} edit={handleEdit} remove={handleRemove} reset={handleReset} />

    return (
        <div>
            <AddDialog
                onClose={onCurrentAdminClose}
                currentData={currentAdmin}
                onSubmit={HandleAdminDialogSubmit(onAdminDialogSubmit)}
                register={register}
                currentlyEditing={currentlyEditing}
                formState={formState}
                setValue={setValue}
            />
            <Grid className="no-scrollbar" data={process(admins ?? [], dataState)} {...dataState} onDataStateChange={onDataChange} dataItemKey="inventoryItemId" sortable pageable>
                <GridToolbar>
                    <div className={classes.addNewRightButton}>
                        <IconButton color="primary" component="span" onClick={forceRefresh}>
                            <Cached color="primary"/>
                        </IconButton>
                        <CustomButton title={t('tenantsList.add_new')}
                                      onClick={() => {
                                          setCurrentlyEditing("add")
                                          setCurrentAdmin({})
                                      }}/>
                    </div>
                </GridToolbar>
                <GridNoRecords>
                    {admins === undefined ?
                        <Box width={250} height={150}><LoadingComponent/></Box> : t('tabels:no_records_text')}
                </GridNoRecords>
                <Column field="inventoryItemId" title="ID" />
                <Column field="name" title={t('adminsList.name')} />
                <Column field="email" title={t('adminsList.email')} />
                <Column field="phone" title={t('adminsList.phone')} />
                <Column cell={CommandsCell} />
            </Grid>
        </div>
    )
}


export default AdminsList
