import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    match: { params: { page: string } }
}

const TenantPage = ({ match }: Props): ReactElement => {
    const { t } = useTranslation("common")

    return <>{t(`breadcrumbs.tenants_pages.${match.params.page}`)}</>
}

export default TenantPage
