/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {ChangeEvent, ReactElement, useEffect, useRef, useState} from 'react'
import {TabStrip, TabStripSelectEventArguments, TabStripTab} from "@progress/kendo-react-layout";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import useStyles from "./CertificateInputsStyle"
import {CertificateType} from "../../../api/fixed/Certificate"

interface Props {
    onInputsChange: (file: any, type: CertificateType | undefined, password: string | undefined, publicKey: string | undefined, privateKey: string | undefined) => void
}

enum CertificateTab {
    UPLOAD_FILE,
    TEXT
}

const CertificateInputs = ({onInputsChange}: Props): ReactElement => {
    const classes = useStyles();
    const {t} = useTranslation("tabels");
    const [fileName, setFileName] = useState<string>(t('certificates.click_to_select'))
    const selectedFile = useRef<any | null>(null);
    const [selectedOption, setSelectedOption] = useState<CertificateType>(CertificateType.encryption);
    const password = useRef<string | undefined>(undefined)
    const publicKey = useRef<string | undefined>(undefined)
    const privateKey = useRef<string | undefined>(undefined)
    const [selectedTab, setSelectedTab] = useState<CertificateTab>(CertificateTab.UPLOAD_FILE);

    const notifyInputsChanged = (): void => {
        onInputsChange(selectedFile.current, selectedOption, password.current, publicKey.current, privateKey.current);
    }

    useEffect(() => {
        if (selectedFile.current != null) {
            setFileName(`${t('certificates.file')}: ${selectedFile.current.name}`)
        } else {
            setFileName(t('certificates.click_to_select'))
        }
    }, [t])

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files) {
            setFileName(`${t('certificates.file')}: ${e.target.files[0].name}`)
            selectedFile.current = e.target.files[0];
        }
        notifyInputsChanged();
    }

    const onTabSelect = (e: TabStripSelectEventArguments): void => {
        setSelectedTab(e.selected);
        setFileName(t('certificates.click_to_select'))
        // @ts-ignore
        selectedFile.current = null;
        publicKey.current = undefined;
        privateKey.current = undefined;
        notifyInputsChanged();
    }

    return (
        <div>
            <FormControl className={classes.FormControlStyle}>
                <InputLabel
                    id="demo-simple-select-label">{t('certificates.type')}</InputLabel>
                <Select
                    fullWidth
                    label={t('certificates.type')}
                    defaultValue={CertificateType.encryption}
                    onChange={e => {
                        // @ts-ignore
                        setSelectedOption(e.target.value)
                        if (e.target.value !== CertificateType.decryption) {
                            password.current = undefined
                        }
                        notifyInputsChanged()
                    }}
                >
                    <MenuItem value={CertificateType.encryption}>{t('certificates.encryption')}</MenuItem>
                    <MenuItem value={CertificateType.decryption}>{t('certificates.decryption')}</MenuItem>
                    <MenuItem value={CertificateType.auth}>{t('certificates.auth')}</MenuItem>
                </Select>
            </FormControl>
            {selectedOption === CertificateType.decryption &&
                <FormControl fullWidth sx={{paddingTop: 2}}>
                    <TextField
                        defaultValue=''
                        label="Password"
                        onChange={e => {
                            password.current = e.target.value
                            notifyInputsChanged()
                        }}/>
                </FormControl>
            }
            <TabStrip className="certificatesTabStrip" selected={selectedTab} onSelect={onTabSelect}>
                <TabStripTab title="PEM/BASE64 Text">
                    <TextField label="Public key" fullWidth sx={{marginTop: 1}} rows={6} multiline onChange={e => {
                        publicKey.current = e.target.value;
                        notifyInputsChanged()
                    }} />
                    {(selectedOption === CertificateType.auth || selectedOption === CertificateType.decryption) &&
                        <TextField label="Private key" fullWidth sx={{marginTop: 2}} rows={6} multiline onChange={e => {
                            privateKey.current = e.target.value;
                            notifyInputsChanged()
                        }}/>
                    }
                </TabStripTab>
                <TabStripTab title="PKCS12 File">
                    <FormControl className={classes.FormControlStyle} >
                        <Box display="flex" flexDirection="column">
                            <Typography sx={{marginTop: 2}}>{t('certificates.certificate_file')}</Typography>
                            <label htmlFor="contained-button-file">
                                <input onChange={handleFileChange} accept="*" id="contained-button-file" style={{display: "none"}} type="file" />
                                <Button component='span' variant='contained' sx={{color: "white"}} fullWidth>
                                    {fileName}
                                </Button>
                            </label>
                        </Box>
                    </FormControl>
                </TabStripTab>
            </TabStrip>

        </div >
    )
}


export default CertificateInputs
