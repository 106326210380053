import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        FormControlStyle: {
            width: "100%",
        },
        ImportCertificateLabel: {
            color: "#868686",
            fontWeight: 400,
            fontSize: "12px",
            marginBottom: 10
        },
        certificateTabStyle: {
            width: "50%"
        }
    }),
);



export default useStyles