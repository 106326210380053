import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Box} from "@mui/material";
import useStyles from "./HeaderStyle";
import GRFlag from "../../Assets/Icons/GR.png";
import EnFlag from "../../Assets/Icons/EN.png";
import Api from "../../api/Api";
import {deleteCookie} from "../../utils";
import Config from "../../app-config";
import {useAppSelector} from "../../app/hooks";

const Header = React.forwardRef(({ isHome }: {isHome?: boolean}, ref) : ReactElement => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const { currentPage } = useAppSelector(state => state.appState);
  const history = useHistory();

  const handleLanguageChange = (): void => {
    if (i18n.language === "de") {
      i18n.changeLanguage("en-gb")
    } else {
      i18n.changeLanguage("de");
    }
  }

  const handleSignOut = (): void => {
    deleteCookie('token');
    Api.setToken(null);
    history.push("/login");
  }

  return (
      <Box ref={ref} flexDirection="row" display="flex" sx={{ bgcolor: isHome ? "lightgray" : "primary.main", padding: 2 }}>
        <Typography variant="h6" noWrap className={classes.title} sx={{ color: isHome ? "black" : "white" }}>
          {t(`AppBar.${currentPage}`)}
        </Typography>
          <div className={classes.RightIcon}>
           <div>
             {!Config.language &&
             <IconButton onClick={handleLanguageChange}>
               <img src={i18n.language === "de" ? EnFlag : GRFlag} alt="Language" />
             </IconButton>
             }
           </div>
           <div>
             <IconButton onClick={handleSignOut}>
               <i className="fas fa-sign-out-alt" style={{ color: isHome ? "black" : "white", fontSize: "large" }} />
             </IconButton>
           </div>
          </div>
      </Box>
  );
})

Header.defaultProps = {
    isHome: false
}

export default Header;
