import React, {ReactElement} from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Box} from "@mui/material";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import useStyles from "./styles";
import ListBreadcrumb from "./ListBreadcrumb";
import TenantBreadcrumb from "./TenantBreadcrumb";
import TenantPage from "./TenantPage";

const nullBreadcrumbs: string[] = []

const routes = [
    { path: "/admins", breadcrumb: ListBreadcrumb },
    { path: "/tenants", breadcrumb: ListBreadcrumb },
    { path: "/", breadcrumb: ListBreadcrumb},
    { path: "/tenants/:id", breadcrumb: TenantBreadcrumb},
    { path: "/tenants/:id/:page", breadcrumb: TenantPage}
]

const SimpleBreadcrumbs = ({ breadcrumbs }: { breadcrumbs: any[] }): ReactElement => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const filteredBreadcrumbs = breadcrumbs.filter(b => !nullBreadcrumbs.includes(b.match.path))

    return (
        <Box display="flex" flexDirection="row" marginTop={1} marginBottom={1}>
            {filteredBreadcrumbs.length > 0 &&
                <Typography>
                    {t('breadcrumbs_text')}&nbsp;
                </Typography>
            }
            <Breadcrumbs aria-label="breadcrumb">
                {filteredBreadcrumbs.map((b, index) => {
                    if (index === filteredBreadcrumbs.length - 1) {
                        return <Typography color="textPrimary" className={classes.lastItemStyle} key={b.match.url}>
                            {b.breadcrumb}
                        </Typography>
                    }
                    return <Link className={classes.normalItemStyle} key={b.match.url}
                                 to={b.match.url}>{b.breadcrumb}</Link>
                })}
            </Breadcrumbs>
        </Box>
    );
}

export default withBreadcrumbs(routes)(SimpleBreadcrumbs)
