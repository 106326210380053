import {CustomButton, Field} from "ndr-designsystem";
import React, {ReactElement, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../app/hooks";
import useStyles from "./TenantDetailsStyle";
import CertificatesDialog from "./CertificatesDialog";

const Certificates = (): ReactElement => {
    const {currentTenant: tenant, certificates} = useAppSelector(state => state.Tenants)
    const {t} = useTranslation(["tabels", "common"])
    const [showUploadCertificatesOpen, setShowUploadsCertificatesOpen] = useState(false)
    const classes = useStyles();

    const handleCertificateDialogOpen = (): void => {
        setShowUploadsCertificatesOpen(true)
    }

    const handleCertificateDialogClose = (): void => {
        setShowUploadsCertificatesOpen(false)
    }

    return <>
        {tenant?.isActive && <div className={classes.CertificateButton}>
            <CustomButton title={t("Common:IMPORT")} onClick={handleCertificateDialogOpen} fullWidth />
            {showUploadCertificatesOpen &&
                <CertificatesDialog open={showUploadCertificatesOpen} onClose={handleCertificateDialogClose} />
            }
        </div>}
        <Field
            inputType="text"
            isEditing={false}
            onValueChange={() => {
            }}
            defaultValue={certificates?.decryption}
            title={t("tenantDetails.decryption")}
        />
        <Field
            inputType="text"
            isEditing={false}
            onValueChange={() => {
            }}
            defaultValue={certificates?.encryption}
            title={t("tenantDetails.encryption")}
        />
        <Field
            inputType="text"
            isEditing={false}
            onValueChange={() => {
            }}
            defaultValue={certificates?.auth}
            title={t("tenantDetails.clientAuth")}
        />
    </>
}

export default Certificates;