import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    match: { path: string }
}

const ListBreadcrumb = ({ match }: Props): ReactElement => {
    const { t } = useTranslation("common")
    let content: string = "";
    if (match.path === "/admins") {
        content = "admins_list"
    } else if (match.path === "/tenants") {
        content = "tenants_list"
    } else if (match.path === "/") {
        content = "home"
    }

    return <>{t(`breadcrumbs.${content}`)}</>
}

export default ListBreadcrumb
