import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { fallbackLanguage, Languages } from "./locales/locales"

import de from "./locales/data/common.de.json";
import en from "./locales/data/common.en.json";

import enTabels from "./locales/data/tabels.en.json";
import deTabels from "./locales/data/tabels.de.json";

import enValidation from "./locales/data/validation.en.json";
import deValidation from "./locales/data/validation.de.json"

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: fallbackLanguage,
        supportedLngs: Languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                common: en,
                tabels: enTabels,
                validation: enValidation
            },
            de: {
                common: de,
                tabels: deTabels,
                validation: deValidation
            },
        },
    });

export default i18n;
