import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";
import {GroupWork} from "@mui/icons-material";
import React, {ReactElement} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const AdminsCard = (): ReactElement => {
    const history = useHistory();
    const { t } = useTranslation("common");

    return <Box display="flex" justifyContent="start">
        <Card sx={{ textAlign: "center"}}>
            <CardActionArea
                sx={{ width: 370, minHeight: 280, padding: 2 }}
                            onClick={() => history.push("/admins")}>
                <GroupWork sx={{ fontSize: 50 }}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('home.admins')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {t('home.admins_details')}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Box>
}

export default AdminsCard