import * as yup from 'yup';

export default function TenantUsersValidation(isResetPW: boolean): any {
    if (isResetPW) {
        return yup.object().shape({
            password: yup
                .string()
                .required()
        })
    }

    return yup.object().shape({
        email: yup
            .string()
            .required('validation:addTenant.RequiredEmail')
            .email('validation:addTenant.InvalidEmail'),

        name: yup
            .string()
            .required('validation:addTenant.RequiredTenantName')
            .min(3, 'validation:addTenant.MinTenantName')
            .max(15, 'validation:addTenant.MaxTenatnName'),

        phone: yup
            .string()
            .max(15, 'validation:addTenant.MaxPhone'),

    })
}


