import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        addAdminButton: {
            color: "white",
            fontSize: "13px",
            height: "37px"
        },
        addNewRightButton: {
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
    }),
);



export default useStyles