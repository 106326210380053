import {Box, Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import CertificateInputs from "./CertificateInputs";
import {CertificateType} from "../../../api/fixed/Certificate";
import {ImportCertificate} from "../store/Thunks";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

interface Props {
    open: boolean
    onClose: () => void
}

const AddDialog = ({open, onClose}: Props): ReactElement => {
    const {t} = useTranslation("tabels");
    const selectedFile = useRef<any>();
    const selectedOption = useRef<CertificateType>()
    const password = useRef<string>()
    const publicKey = useRef<string>()
    const privateKey = useRef<string>()
    const dispatch = useAppDispatch();
    const [errors, setErrors] = useState<string[]>([])
    const [canSend, setCanSend] = useState(false)
    const {currentTenant: tenant} = useAppSelector(state => state.Tenants)

    const onDialogSubmit= (): void => {
        let certificateType: "smime-decryption" | "smime-encryption" | "client-auth" | undefined;

        switch (selectedOption.current) {
            case CertificateType.decryption:certificateType = "smime-decryption"; break;
            case CertificateType.encryption:certificateType = "smime-encryption"; break;
            case CertificateType.auth:certificateType = "client-auth"; break;
            default:
                // eslint-disable-next-line no-alert
                alert("ERROR")
        }
        dispatch(ImportCertificate({
            type: certificateType,
            CF: selectedFile.current ?? undefined,
            tenantID: tenant?.inventoryItemId,
            revision: tenant?.revision,
            privateKey: privateKey.current,
            publicKey: publicKey.current,
            password: password.current
        })).unwrap().then(() => {
            onClose();
        }).catch((e: {errors: {message: string}[]}) => {
            setErrors(e.errors.map(err => err.message))
        })
    }

    return <Dialog
        open={open}
        fullWidth
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        {/* {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>} */}
        <DialogContent sx={{display: "flex", flexDirection: "column"}}>
            <CertificateInputs
                onInputsChange={(file, option, pass, pub, prv) => {
                    setCanSend(false);
                    if ((option === CertificateType.auth || (option === CertificateType.decryption && pass != null && pass.length > 0)) && ((pub != null && prv != null && prv.length > 0 && prv.length > 0) || file != null)) {
                        setCanSend(true);
                    } else if (option === CertificateType.encryption && ((pub != null && pub.length > 0) || file != null)) {
                        setCanSend(true);
                    }
                    selectedFile.current = file;
                    selectedOption.current = option;
                    password.current = pass;
                    publicKey.current = pub;
                    privateKey.current = prv;
            }} />
            {errors.length > 0 &&
                <Box width="100%" display="flex" justifyContent="center">
                    {errors.map(e =>
                        <Typography variant="caption" color="error">
                            {e}
                        </Typography>
                    )}
                </Box>
            }

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                {t("common:cancel")}
            </Button>
            <Button onClick={onDialogSubmit} color="primary" disabled={!canSend}>
                {t('common:submit')}
            </Button>
        </DialogActions>
    </Dialog>
}

export default AddDialog