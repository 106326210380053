import React, {ReactElement, useCallback, useMemo} from "react";
import {Route, Switch, useLocation, withRouter} from "react-router-dom";
import {Box} from "@mui/material";
import {useWindowDimensions} from "ndr-designsystem";
import useStyles from "./style";
import TenantDetails from "../../features/Tenants/TenantsDetails/TenantDetails";
import TenantsList from "../../features/Tenants/TenantsList/TenantsList";
import AdminsList from "../../features/Admins/AdminsList"
import Header from "../../Components/Header/Header";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setHeaderHeight} from "../../features/appStateSlice";
import HomeContent from "./HomeContent";
import SimpleBreadcrumbs from "../../Components/Breadcrumbs/SimpleBreadcrumbs";

const Home = (): ReactElement => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { headerHeight } = useAppSelector(state => state.appState)
    const { height, width } = useWindowDimensions();
    const location = useLocation();

    const headerRef = useCallback(node => {
        if (node !== null) {
            dispatch(setHeaderHeight(node.clientHeight));
        }
    }, []);

    const Background = useCallback(() => <Box sx={{
        position: "absolute",
        top: headerHeight ?? 0,
        left: 0,
        width: "100%",
        // eslint-disable-next-line
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgimg.png'}),url(${process.env.PUBLIC_URL + '/bgimg.jpg'})`,
        backgroundSize: "cover,cover",
        height: (height ?? 0) - (headerHeight ?? 0),
        zIndex: -2,
    }}/>, [headerHeight, height])

    const Logo = useCallback(() => <Box sx={{
        position: "absolute",
        top: (headerHeight ?? 0) + 20,
        right: 20,
        width: ((width ?? 0) / 1920) * 300,
        height: ((width ?? 0) / 1920) * 105,
        // eslint-disable-next-line
        backgroundImage: `url(${process.env.PUBLIC_URL + '/logo.png'})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        zIndex: -1,
    }}/>, [headerHeight, width])

    const isHome = useMemo(() => ["", "/"].includes(location.pathname), [location])

    return (
        <Box className={classes.root}>
            <Header ref={headerRef} isHome={isHome}/>
            <Box marginX={isHome ? 0 : 2} display="flex" flex={1}>
                <Box display="flex" flexDirection="column" width="100%" height="100%">
                {!isHome &&
                    <SimpleBreadcrumbs/>
                }
                <Switch>
                    <Route path="/admins">
                        <AdminsList />
                    </Route>
                    <Route path="/tenants/:id/:page">
                        <TenantDetails />
                    </Route>
                    <Route path="/tenants/:id">
                        <TenantDetails />
                    </Route>
                    <Route path="/tenants">
                        <TenantsList />
                    </Route>
                    <Route path="/dashboard" >
                        dashboard
                    </Route>
                    <Route path="/">
                        <Logo/>
                        <Background/>
                        <HomeContent/>
                    </Route>
                </Switch>
                </Box>
            </Box>
        </Box>
    )
}
export default withRouter(Home);
