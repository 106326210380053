import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        paperDesign: {
            padding: 20,
            height: "90%",
            width: 280,
            margin: "60px auto",

        },
        forgotInputs: {
            marginTop: 15
        },
        newPasswordButton: {
            color: "white"
        },
       LoginPageButton: {
            fontSize: 12
        }
    }))


export default useStyles