import {
    CustomButton,
    Field,
    GeneralSimpleElementTable,
    isFieldValid,
    isSchemaValid,
    PageStatus
} from "ndr-designsystem";
import React, {ReactElement, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import SchemaConstants from "../../../utils/schemaConstants";
import {
    setCallRetryDelays,
    setOnCallServiceEmail,
    setOnCallServicePhone,
    swapCallRetries,
    updateNewCallRetryDelay
} from "../store/TenantsSlice";
import {NumberSchema, OnCallTabSchema} from "../../../validations/TenantDetailsValidation";
import useStyles from "./TenantDetailsStyle";
import Api from "../../../api/Api";
import Config from "../../../app-config";

const OnCallTab = ({pageStatus}: {pageStatus: PageStatus}): ReactElement => {
    const dispatch = useAppDispatch();
    const isEditing = pageStatus === PageStatus.EDIT
    const isAdding = pageStatus === PageStatus.ADD
    const { currentTenant: tenant} = useAppSelector(state => state.Tenants)
    const {t} = useTranslation("tabels")
    const onCallTabSchema = OnCallTabSchema();
    const classes = useStyles();

    const getCallRetryDelaysActions = useCallback(() => ({
        setElements: (elements: number[] | undefined) => {
            dispatch(setCallRetryDelays(elements))
        },
        updateNewElement: (element: number) => {
            dispatch(updateNewCallRetryDelay(element));
        },
        swapElements: (startIndex: number, endIndex: number) => {
            dispatch(swapCallRetries({startIndex, endIndex}))
        }
    }), [dispatch]);


    const HandleTestActivation = async (): Promise<void> => {
        if (tenant?.onCallServicePhone) {
            await Api.CallPhone(tenant.onCallServicePhone, Config.callService.message);
        }
    }

    return <>
        <Field
            inputType="text"
            isEditing={isEditing}
            valueCheckFunction={onCallServiceEmail => isFieldValid(onCallTabSchema, [SchemaConstants.onCallServiceEmail], { onCallServiceEmail })}
            onValueChange={(onCallServiceEmail) => dispatch(setOnCallServiceEmail(onCallServiceEmail))}
            defaultValue={tenant?.onCallServiceEmail}
            title={t("tenantDetails.onCallServiceEmail")}
        />
        <Field
            inputType="text"
            isEditing={isEditing}
            valueCheckFunction={onCallServicePhone => isFieldValid(onCallTabSchema, [SchemaConstants.onCallServicePhone], { onCallServicePhone })}
            onValueChange={(onCallServicePhone) => dispatch(setOnCallServicePhone(onCallServicePhone))}
            defaultValue={tenant?.onCallServicePhone}
            title={t("tenantDetails.onCallServicePhone")}
        />
        <GeneralSimpleElementTable
            canReorder
            defaultOpen
            noRecordsText={t("common:no_records_text")}
            onMoreThanOneError={() => {
                alert(t("one_by_one"))
            }}
            addButtonText={t("common:add_new")}
            elements={tenant?.callRetryDelays}
            valueCheckFunction={val => isSchemaValid(NumberSchema(), val)}
            defaultElement={undefined}
            gridCheckFunction={callRetryDelays => isFieldValid(onCallTabSchema, [SchemaConstants.callRetryDelays], { callRetryDelays })}
            pageStatus={pageStatus}
            actions={getCallRetryDelaysActions()}
            type="number"
            title={t('tenantDetails.callRetryDelays')}
        />
        {!!tenant?.onCallServicePhone?.length && (!isEditing || !isAdding) &&
            <div className={classes.CertificateButton}>
                <CustomButton onClick={HandleTestActivation} title={t("tenantDetails.TestActivation")} fullWidth />
            </div>
        }
    </>
}

export default OnCallTab;