import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {Cached} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {CustomButton, FieldWithGridProps, PageStatus} from "ndr-designsystem";
import useStyles from "./styles";

interface Props extends FieldWithGridProps {
    disabled: boolean,
    onAddClick: () => void
    onCancelClick: () => void
    onSaveClick: () => void
    onEditClick?: () => void
    onRefresh?: () => void
    toggleActivation: () => void
    toggleActivationText: string
    login?: () => void
}

const DetailsViewToolbar = ({
                                pageStatus,
                                disabled,
                                onAddClick,
                                onSaveClick,
                                onCancelClick,
                                onEditClick,
                                onRefresh,
                                toggleActivation,
                                toggleActivationText,
                                login
                            }: Props): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation("tabels");

    return (
        <div className={classes.titleContainer}>
            <div className={`${classes.addNewRightButton} ${classes.addNewTitleButton}`}>
                {(onRefresh && pageStatus === PageStatus.VIEW) &&
                    <IconButton color="primary" component="span" onClick={onRefresh}>
                        <Cached color="primary"/>
                    </IconButton>
                }
                {pageStatus === PageStatus.ADD &&
                    <>
                        <div />
                        <CustomButton title={t("tenantDetails.submit")} disabled={disabled}
                                      onClick={onAddClick}/>
                    </>
                }
                {pageStatus === PageStatus.EDIT &&
                    <>
                        <div/>
                        <div>
                            <CustomButton title={t("tenantDetails.cancel")}
                                          onClick={onCancelClick}/>
                            <CustomButton title={t("tenantDetails.submit")} disabled={disabled}
                                          onClick={onSaveClick}/>
                        </div>
                    </>
                }
                {pageStatus === PageStatus.VIEW &&
                    <div>
                        {onEditClick &&
                            <CustomButton title={t("tenantDetails.edit")} onClick={onEditClick}/>
                        }
                        {login &&
                            <CustomButton title={t("common:LOGIN")} onClick={login}/>
                        }
                        <CustomButton title={toggleActivationText} onClick={toggleActivation}/>

                    </div>
                }
            </div>
        </div>
    );
};

DetailsViewToolbar.defaultProps = {
    onRefresh: undefined,
    onEditClick: undefined,
    login: undefined
};

export default DetailsViewToolbar;