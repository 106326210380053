/* eslint-disable no-alert */
import React, {ReactElement, useEffect, useState} from 'react';
import {Route, Switch, useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {LoginOrForgot} from 'ndr-designsystem';
import ProtectedRoutes from './Routes/ProtectedRoutes';
import Api from "./api/Api";
import Home from "./pages/Home/Home";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword"
import './App.css';
import {deleteCookie, getCookieValue} from "./utils";
import AccessResult from './api/fixed/LoginResult';
import Config from "./app-config";
import buildTitle from "./Components/titleHelper";
import {useAppSelector} from "./app/hooks";


function App(): ReactElement {
  const [isAuth, setIsAuth] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const {currentTenant} = useAppSelector(state => state.Tenants)
  const history = useHistory();
  const { t, i18n } = useTranslation("common");
  const listenToHistoryChanges = (): void => {
    buildTitle(history.location.pathname, currentTenant?.name);
    const cookie = getCookieValue('token');
    if (!cookie) setIsAuth(false);
  }

  useEffect(() => {
    const unregister = history.listen(listenToHistoryChanges)
    buildTitle(history.location.pathname, currentTenant?.name)
    return () => unregister();
  }, [history, currentTenant])


  useEffect(() => {
    const tryLogin = async (): Promise<void> => {
      const cookie = getCookieValue("token");

      if (cookie != null) {
        Api.setToken(cookie);
        const accessResult = await Api.checkIfUserHasAccess();

        if (accessResult === AccessResult.EXPIRED) {
          setErrorMessage(t("alert_messages.token_expired"));
          deleteCookie("token");
          setIsAuth(false);
          return;
        }

        if (accessResult === AccessResult.NO_ACCESS) {
          deleteCookie("token");
          setErrorMessage(t("alert_messages.access_denied"));
          setIsAuth(false);
          return;
        }

        setIsAuth(true);

        if (history.location.state === undefined)
          history.replace(history.location.pathname);
        else if (history.location.pathname.includes("login"))
          history.goBack();

      } else {
        setIsAuth(false);
      }
    };
    tryLogin();
  }, [history, t])

  const onLogin = async (data: { username?: string, password?: string }): Promise<void> => {
    setErrorMessage(undefined);
    setIsLoggingIn(true);

    const res = await Api.HandleLoginAPI(data.username, data.password);
    setIsLoggingIn(false);

    switch (res) {
      case AccessResult.SUCCESS: {
        setIsAuth(true);
        const { state } = history.location as { state: { from: { pathname: string } } };
        if (state) {
          history.replace(state.from.pathname);
        } else {
          history.replace("/");
        }
      }
        break;
      case AccessResult.NO_ACCESS:
        setErrorMessage(t("alert_messages.access_denied"));
        break;
      case AccessResult.INCORRECT_DETAILS:
        setErrorMessage(t("alert_messages.incorrect_credentials"));
        break;
      default:
        // eslint-disable-next-line no-alert
        window.alert("ERROR");
    }
  }

  const handleLanguageChange = (lang: "en" | "de"): void => {
    if (lang === "en")
      i18n.changeLanguage("en-gb");
    else
      i18n.changeLanguage("de");
  }

  if (isAuth === undefined) return <></>

  return (
      <Switch>
        <Route path="/forget" >
          <ForgetPassword />
        </Route>
        <Route path="/login" >
          <LoginOrForgot
              usernameLabel={t("Login.Username")}
              passwordLabel={t("Login.Password")}
              usernamePlaceholder={t("Login.enterUsername")}
              passwordPlaceholder={t("Login.enterPassword")}
              submitText={t("Login.login")}
              // redirectButtonText={t("Login.Forgot") ?? ""}
              // redirectButtonClick={() => history.push("/forget")}
              onLanguageChange={!Config.language ? handleLanguageChange : undefined}
              isPerformingActions={isLoggingIn}
              errorMessage={errorMessage}
              onSubmit={onLogin}
          />
        </Route>
        <ProtectedRoutes path="/" isAuth={isAuth} Component={Home} />
      </Switch>
  );
}

export default App;
