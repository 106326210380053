import React, {useCallback, useEffect, useState} from "react";
import {List} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import {useHistory, useParams} from "react-router-dom";
import {AlertDialog, CustomTabTitle, LoadingComponent, PageStatus} from "ndr-designsystem"
import useStyles from "./TenantDetailsStyle";
import {CertificateType} from "../../../api/fixed/Certificate"
import {useAppDispatch, useAppSelector} from "../../../app/hooks"
import {AddTenant, editTenantActivation, editTenantDetails, fetchCertificate, fetchTenantByID} from "../store/Thunks";
import {setCertificates, setCurrentTenant} from "../store/TenantsSlice"
import Tenant from "../../../api/fixed/Tenant";
import MainContact from "./MainContact";
import GeneralTab from "./GeneralTab";
import OnCallTab from "./OnCallTab";
import {
    GeneralTabSchema,
    MainContactSchemaFromTenant,
    OnCallTabSchema
} from "../../../validations/TenantDetailsValidation";
import DetailsViewToolbar from "../../../Components/DetailsViewToolbar";
import Certificates from "./Certificates";
import Users from "./Users";
import {setCurrentPage} from "../../appStateSlice";

interface RouteParams {
    id: string
    page: string
}

enum TenantPage {
    GENERAL,
    MAIN_CONTACT,
    CONTACT,
    CERTIFICATES,
    USERS
}

const TenantDetails: React.FC<{}> = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { id, page } = useParams<RouteParams>();
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.VIEW);
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const [AlertOpen, setAlertOpen] = useState(false);
    const [LoginURL, setLoginURL] = useState<string>();
    const { currentTenant: tenant } = useAppSelector(state => state.Tenants)
    const { t } = useTranslation(['tabels', 'common']);

    const history = useHistory();
    const generalTabSchema = GeneralTabSchema(isAdding);
    const mainContactSchema = MainContactSchemaFromTenant();
    const onCallTabSchema = OnCallTabSchema();

    const isGeneralTabValid = generalTabSchema.isValidSync(tenant);
    const inMainContactSchemaValid = mainContactSchema.isValidSync(tenant);
    const isOnCallSchemaValid = onCallTabSchema.isValidSync(tenant);

    useEffect(() => {
        if (id !== "add") {
            dispatch(fetchTenantByID(id));
        } else {
            dispatch(setCurrentTenant({}));
            setPageStatus(PageStatus.ADD);
        }
        return () => dispatch(setCurrentTenant(undefined))

    }, [dispatch, id])

    useEffect(() => {
        if (id !== "add") {
            dispatch(fetchCertificate({ tenantID: id, CT: CertificateType.decryption }))
            dispatch(fetchCertificate({ tenantID: id, CT: CertificateType.encryption }))
            dispatch(fetchCertificate({ tenantID: id, CT: CertificateType.auth }))
        }
        return () => dispatch(setCertificates({
            encryption: "none",
            decryption: "none",
            auth: "none"
        }))
    }, [dispatch, id])


    useEffect(() => {
        if (id !== "add" && tenant?.subdomain) {
            setLoginURL([`${window.location.protocol}//${tenant.subdomain}.ui`, ...window.location.hostname.split('.').splice(1)].join('.'))
        }
        return () => {
            setLoginURL(undefined)
        }
    }, [id, tenant?.subdomain])

    const handleTenantAdd = async (): Promise<void> => {
        const result: Tenant = await dispatch(AddTenant(tenant!)).unwrap()
        if (result.inventoryItemId) {
            history.replace(`/tenants/${result.inventoryItemId}`)
            setPageStatus(PageStatus.VIEW)
        }
    }

    const handleRefresh = async (): Promise<void> => {
        await dispatch(fetchTenantByID(id));
    }

    const handleTenantEdit = async (): Promise<void> => {
        dispatch(editTenantDetails(tenant!)).then(() => {
            setPageStatus(PageStatus.VIEW)
        })
    }

    const HandleActive = (): void => {
        if (tenant)
            dispatch(editTenantActivation({ id: tenant.inventoryItemId!, revision: tenant.revision!, active: !tenant.isActive }))
    }

    const HandleEditMode = (): void => {
        if (pageStatus !== PageStatus.EDIT)
            setPageStatus(PageStatus.EDIT);
        else
            setAlertOpen(true)
    }

    useEffect(() => {
        if (pageStatus === PageStatus.EDIT)
            dispatch(setCurrentPage("edit_tenant"));
        else if (pageStatus === PageStatus.ADD)
            dispatch(setCurrentPage("add_tenant"))
        else
            dispatch(setCurrentPage("tenant_details"))
    }, [dispatch, pageStatus])

    const onAlertAgree = (): void => {
        setPageStatus(PageStatus.VIEW)
        dispatch(fetchTenantByID(id));
        setAlertOpen(false)
    }

    const onAlertCancel = (): void => {
        setAlertOpen(false)
    }

    const transformPageStringToPageIndex = useCallback(() => {
        if (page) {
            switch (page) {
                case "main-contact":
                    return TenantPage.MAIN_CONTACT
                case "certificates":
                case "users": {
                    let decrease = 0;
                    if (tenant?.useAvatForActivations) {
                        decrease = 1;
                    }

                    // @ts-ignore
                    return TenantPage[page.toUpperCase()] - decrease
                }
                default:
                    // @ts-ignore
                    return TenantPage[page.toUpperCase()]
            }
        }
        return 0;
    }, [page, tenant]);

    const selectTab = (e: any): void => {
        let { selected } = e;
        if (selected === TenantPage.CONTACT) {
            if (tenant?.useAvatForActivations) {
                selected = TenantPage.CERTIFICATES
            }
        } else if (selected === TenantPage.CERTIFICATES) {
            if (tenant?.useAvatForActivations) {
                selected = TenantPage.USERS
            }
        }

        switch (selected) {
            case TenantPage.GENERAL:
                history.replace(`/tenants/${id}`)
                break;
            case TenantPage.MAIN_CONTACT:
                history.replace(`/tenants/${id}/main-contact`)
                break;
            case TenantPage.CONTACT:
                history.replace(`/tenants/${id}/contact`)
                break;
            case TenantPage.CERTIFICATES:
                history.replace(`/tenants/${id}/certificates`)
                break;
            case TenantPage.USERS:
                history.replace(`/tenants/${id}/users`)
                break;
            default:
                break;
        }
    };

    if (tenant === undefined) return <LoadingComponent />
    return (
        <List disablePadding style={{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            {tenant?.isActive &&
                <div className={classes.activeStyle}>
                    {t("ACTIVE")}
                </div>}

            <DetailsViewToolbar
                disabled={!isGeneralTabValid}
                onSaveClick={handleTenantEdit}
                pageStatus={pageStatus}
                onAddClick={handleTenantAdd}
                onCancelClick={HandleEditMode}
                onEditClick={[0, 1, 2].includes(transformPageStringToPageIndex()) ? HandleEditMode : undefined}
                onRefresh={handleRefresh}
                toggleActivation={HandleActive}
                toggleActivationText={tenant?.isActive ? t('tenantDetails.deactivate') : t('tenantDetails.activate')}
                login={tenant?.isActive ? () => { window.location.href = LoginURL ?? "" } : undefined}
            />
            <AlertDialog text={t("common:cancel_edit_mode")} onAgree={onAlertAgree} alertOpen={AlertOpen} onCancel={onAlertCancel} cancelText={t("common:Disagree")} acceptText={t("common:Agree")} />
            <TabStrip tabPosition="left" selected={transformPageStringToPageIndex()} className={classes.scrollableTabs}
                      onSelect={selectTab}>
                <TabStripTab title={<CustomTabTitle isValid={isGeneralTabValid}
                                                    title={t("tenantDetails.tabs.general")}/>} >
                    <GeneralTab pageStatus={pageStatus} />
                </TabStripTab>
                <TabStripTab title={<CustomTabTitle isValid={inMainContactSchemaValid}
                                                    title={t("tenantDetails.tabs.mainContact")}/>}>
                    <MainContact pageStatus={pageStatus} />
                </TabStripTab>
                {!tenant?.useAvatForActivations &&
                    <TabStripTab title={<CustomTabTitle isValid={isOnCallSchemaValid}
                                                        title={t("tenantDetails.tabs.onCall")}/>}>
                        <OnCallTab pageStatus={pageStatus}/>

                    </TabStripTab>
                }
                {/* TODO: Add tab validation */}
                {(!isAdding && !isEditing) &&
                    <TabStripTab title={<CustomTabTitle isValid
                                                        title={t("tenantDetails.tabs.certificates")}/>} >
                        <Certificates />
                    </TabStripTab>
                }
                {/* TODO: Add tab validation */}
                {(!isAdding && !isEditing) &&
                    <TabStripTab title={<CustomTabTitle isValid
                                                        title={t("tenantDetails.tabs.users")}/>}>
                        <Users />
                    </TabStripTab>
                }
            </TabStrip>
        </List >
    )

}

export default TenantDetails
