import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        TenantHeader: {
            marginBottom: "15px"

        },
        scrollableTabs: {
            height:"100%",
            '& .k-tabstrip-items': {
                userSelect: "none",
                width: 250,
            }
        },
        activeStyle: {
            textAlign: "center",
            backgroundColor: "green",
            color: "white",
            fontSize: 20,
            fontWeight: 700,
            userSelect: "none",
            marginBottom: 10
        },
        HeaderButton: {
            color: "white",
            fontSize: "12px",
            marginLeft: 20,
            height: 35
        },
        submitButton: {
            color: "white",
            fontSize: 15,
            marginTop: 25,
            marginBottom: 25,
            width: "100%"
        },
        CertificateButton: {
            margin: "10px 20px"
        },
        dontShow: {
            display: "none"
        },
        activationTestButton: {
            color: "white",
            width: "100%"
        }
    }),
);



export default useStyles