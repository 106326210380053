import {Grid, GridCellProps, GridNoRecords, GridToolbar} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {CustomButton, GridFieldActionsCell} from "ndr-designsystem";
import React, {ReactElement, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import TenantUsersValidation from "../../../validations/TenantUsersValidation";
import TenantUser from "../../../api/fixed/TenantUser";
import {AddTenantUser, DeleteTenantUser, EditTenantUser, ResetTenantUserPass} from "../store/Thunks";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import AddDialog from "../../../Components/AddDialog/AddDialog";

const Users = (): ReactElement => {
    const [currentUser, setCurrentUser] = useState<TenantUser | undefined>(undefined);
    const [currentlyEditing, setCurrentlyEditing] = useState<"add" | "edit" | "reset" | undefined>()
    const dispatch = useAppDispatch();
    const { currentTenant: tenant } = useAppSelector(state => state.Tenants)
    const TenantUsersValidationSchema = TenantUsersValidation(currentlyEditing === "reset")
    const { t } = useTranslation(['tabels', 'common']);

    const { register, reset, formState, handleSubmit: HandleUsersDialogSubmit, setValue } = useForm<any>({
        mode: 'onTouched',
        resolver: yupResolver(TenantUsersValidationSchema)
    });

    const onCurrentUserClose = (): void => {
        setCurrentUser(undefined)
        reset()
    }

    const handleEdit = (adminIndex: number): void => {
        const newUser = (tenant?.users ?? [])[adminIndex]
        setCurrentUser(newUser);
        setCurrentlyEditing("edit")
        setValue("name", newUser.name);
        setValue("email", newUser.email);
        setValue("phone", newUser.phone);
    }

    const HandleAddDialogUser = (): void => {
        setCurrentlyEditing("add")
        setCurrentUser({

        })
    }

    const onUsersDialogSubmit: SubmitHandler<any> = async (data) => {
        switch (currentlyEditing) {
            case "add": await dispatch(AddTenantUser({...data, tenantId: tenant?.inventoryItemId, tenantRevision: tenant?.revision})); break;
            case "edit": {
                const { password, ...rest } = data
                await dispatch(EditTenantUser({ ...rest, inventoryItemId: currentUser?.inventoryItemId, revision: currentUser?.revision }))
            } break;
            case "reset": await dispatch(ResetTenantUserPass({ UserID: currentUser?.inventoryItemId ?? "", pass: data.password })); break;
            default: break;
        }
        setCurrentUser(undefined);
        reset();
    }

    const handleRemove = (adminIndex: number): void => {
        dispatch(DeleteTenantUser((tenant?.users ?? [])[adminIndex]))
    }

    const handleReset = (adminIndex: number): void => {
        const newUser = (tenant?.users ?? [])[adminIndex]
        setCurrentlyEditing("reset")
        setCurrentUser(newUser);
        setValue("name", newUser.name);
        setValue("email", newUser.email);
        setValue("phone", newUser.phone);
    }

    const commandCell = (props: GridCellProps): any =>
    (
        <GridFieldActionsCell {...props} edit={handleEdit} remove={handleRemove} reset={handleReset} />
    )

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset();
        }
    }, [formState, reset])

    return <>
        <Grid data={tenant?.users} className="no-scrollbar" dataItemKey="inventoryItemId">
            {tenant?.isActive && <GridToolbar >
                <CustomButton title={t('tenantsList.add_new')}
                              onClick={HandleAddDialogUser}/>
            </GridToolbar>}
            <GridNoRecords>{t('tabels:no_records_text')}</GridNoRecords>
            <Column field="inventoryItemId" title="ID" editable={false} />
            <Column field="name" title={t('tenantDetails.name')} />
            <Column field="phone" title={t('tenantDetails.phone')} />
            <Column field="email" title={t('tenantDetails.email')} />
            {tenant?.isActive && <Column cell={commandCell} />}
        </Grid>

        <AddDialog
            onClose={onCurrentUserClose}
            currentData={currentUser}
            onSubmit={HandleUsersDialogSubmit(onUsersDialogSubmit)}
            register={register}
            currentlyEditing={currentlyEditing}
            formState={formState}
            setValue={setValue}
        />
    </>
}

export default Users