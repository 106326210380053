import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {removeNulls} from "ndr-designsystem";
import Tenant, {TenantActivationRequest} from "../../../api/fixed/Tenant"
import GridDataState from "../../../api/fixed/GridDateState";
import Api from "../../../api/Api";
import {RootState} from "../../../app/store";
import TenantUser from "../../../api/fixed/TenantUser";
import {IEcertificate} from "../../../api/fixed/Certificate";

export const fetchTenantListByGridDataState = createAsyncThunk(
    'TenantList/fetch',
    async (gridState: GridDataState, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;
        let lastID;

        if (gridState.skip !== undefined && gridState.take !== undefined) {
            const lastItemIndex = gridState.skip + gridState.take - 1
            if (state.Tenants.tenants && state.Tenants.tenants?.length !== 0 && gridState.skip !== 0) {
                lastID = state.Tenants.tenants[lastItemIndex].inventoryItemId
            }
            return (await Api.fetchTenantList(lastID, gridState.take)).map(t => removeNulls(t));

        }
        return []
    }
);

export const fetchTenantByID = createAsyncThunk('TenantList/fetchByID',
    async (id: string, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;
        const TenantFromMemory = state.Tenants.tenants?.find((item: Tenant) => item.inventoryItemId === id)
        if (TenantFromMemory) {
            return TenantFromMemory
        }
        return removeNulls(await Api.FetchTenantByID(id))
    })


export const editTenantActivation = createAsyncThunk(
    'TenantActivation/edit',
    async (TenantActiveState: TenantActivationRequest) => {
        const response = await Api.HandleTenantActivation(TenantActiveState)

        if (response.status === 200)
            return { ...response.data, activeState: TenantActiveState.active }

        return "error"
    }
);

export const AddTenant = createAsyncThunk("Tenant/add",
    async (details: Tenant) => {
        const response = await Api.HandleAddTenant(details);
        if (response.status === 200) {
            const newContact = {
                ...details,
                mainContact: response.data.mainContact ? {...details.mainContact, ...response.data.mainContact} : null
            }
            return { ...response.data.tenant, ...newContact, users: [], isActive: true, controllableResourcesCount: 0, activationsCount: 0 }
        }
        return "error"
    })

export const editTenantDetails = createAsyncThunk('TenantDetails/edit',
    async (newDetails: Tenant) => {
        const response = await Api.HandleEditTenantDetails(newDetails)
        if (response.status === 200) {
            const { revision, inventoryItemId, ...rest } = newDetails
            if (response.data.mainContact !== null) {
                rest.mainContact = { ...rest.mainContact, revision: response.data.mainContact.revision, inventoryItemId: response.data.mainContact.inventoryItemId }
            }
            else {
                rest.mainContact = {
                    ...rest.mainContact,
                    inventoryItemId: "",
                    email: "",
                    name: "",
                    phone: "",
                    revision: 0
                }
            }
            return { ...response.data.tenant, ...rest }
        }
        return "error"
    })

export const AddTenantUser = createAsyncThunk("TenantUser/add",
    async (details: {
        password: string, tenantId: string | undefined, tenantRevision: number | undefined,
        name: string, email: string, phone: string
    }) => {
        const response = await Api.HandleAddTenantUser(details)
        const { tenantId, tenantRevision, password, ...mainDetails } = details
        return { user: { ...mainDetails, ...response.data.user }, tenant: { ...response.data.tenant } }
    })

export const EditTenantUser = createAsyncThunk("TenantUser/edit",
    async (details: TenantUser, thunkAPI) => {
        const state: RootState = thunkAPI.getState() as RootState;
        const user = state.Tenants.currentTenant?.users?.find((item) =>
            item.inventoryItemId === details.inventoryItemId &&
            item.name === details.name &&
            item.phone === details.phone &&
            item.email === details.email
        )
        if (user)
            return { ...details }

        const response = await Api.HandleEditTenantUser(details)
        const { inventoryItemId, revision, ...mainDetails } = details
        return { ...mainDetails, ...response.data }
    })
export const DeleteTenantUser = createAsyncThunk('TenantUser/delete',
    async (details: TenantUser) => {
        const response = await Api.HandleDeleteTenantUser(details)
        if (response.status === 200)
            return details.inventoryItemId
        return "error"

    })

export const ImportCertificate = createAsyncThunk('Certificate/import',
    async (details: IEcertificate, {rejectWithValue}) => {
        try {
            const response = await Api.HandleImportCertificate(details)
            return { data: response.data, type: details.type }
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue(error.response!.data)
        }
    })

export const fetchCertificate = createAsyncThunk('Certificate/fetch',
    async (details: IEcertificate) => {
        const response = await Api.HandleFetchCertificate(details)
        return { date: response.uploadedDate, type: details.CT }

    })

export const ResetTenantUserPass = createAsyncThunk('TenantUser/ResetPass',
    async (Details: { UserID: string, pass: string }) => {
        await Api.ResetUserPass(Details)
        // eslint-disable-next-line no-console
        // no need tp return the response we are storing passwords only in the DB
    })
