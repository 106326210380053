import * as yup from 'yup';

export const NumberSchema = (): yup.NumberSchema => yup.number().integer().max(1000)
export const EmailSchema = (): yup.StringSchema => yup.string().email().min(5)

export const OnCallTabSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    onCallServiceEmail: yup
        .string()
        .email('validation:addTenant.InvalidEmail'),
    onCallServicePhone: yup
        .string()
        .max(15, 'validation:addTenant.MaxPhone'),
    callRetryDelays: yup.array().nullable().of(NumberSchema()).required("validation:required")
})

export const MainContactSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    email: yup
        .string()
        .email('validation:addTenant.InvalidEmail'),
    name: yup
        .string()
        .matches(/^[a-zA-Z\s]{0,35}$/)
        .min(0, 'validation:addTenant.MinTenantName')
        .max(15, 'validation:addTenant.MaxTenatnName'),
    phone: yup
        .string()
        .max(15, 'validation:addTenant.MaxPhone'),
})

export const MainContactSchemaFromTenant = (): yup.ObjectSchema<any> => yup.object().shape({
    mainContact: MainContactSchema()
})

export const GeneralTabSchema = (isAdding: boolean): yup.ObjectSchema<any> => {
    const mainSchema = yup.object().shape({
        name: yup
            .string()
            .matches(/^[a-zA-Z\s]{0,35}$/)
            .required('validation:addTenant.RequiredUsername')
            .min(4, 'validation:addTenant.MinUsername')
            .max(15, 'validation:addTenant.MaxUsername'),
        address: yup
            .string()
            .max(25, 'validation:addTenant.MaxAddress'),
        exPostRecipients: yup.array().of(EmailSchema())
    })

    if (isAdding) {
        return mainSchema.concat(yup.object().shape({
            subdomain: yup
                .string()
                .required()
                .matches(/^[a-z\s]+$/, 'validation:addTenant.onlyAlphabets'),
            marketPartnerIDCode: yup
                .string()
                .required(),
            marketPartnerIDEncoding: yup
                .string()
                .required(),

        }))
    }

    return mainSchema
}

export const TenantDetailsValidation = (isAdding: boolean): yup.ObjectSchema<any> => yup.object().shape({
    mainContact: MainContactSchema()
}).concat(GeneralTabSchema(isAdding)).concat(OnCallTabSchema())

