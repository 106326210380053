import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        formControlStyle: {
            width: "100%", marginTop: 20
        },
        err: {
            color: "red",
            marginTop: 7,
            fontSize: 12
        },
        GenerateNewPassButton: {
            color: "white"
        }
    }),
);



export default useStyles