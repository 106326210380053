export enum CertificateType {
    decryption = "0",
    encryption = "1",
    auth = "2"
}
export default interface ICertificate {

    encryption: string,
    decryption: string,
    auth: string

}

export interface IEcertificate {
    type?: string,
    CF?: File,
    tenantID: string | undefined,
    revision?: number | undefined,
    password?: string
    publicKey?: string
    privateKey?: string
    CT?: CertificateType
}

export interface ICertificateSubmitForm {
    certificateType: CertificateType,
    certificateFile: File[]
}
