import {AsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {deleteCookie} from "../utils";

interface AddNewSimplePlanningDataCommon {
    show: boolean
    type: "group" | "resource",
    id: string,
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>
type FullfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>

export interface IAppState {
    currentPage: string | null,
    tenantAccessDenied: boolean,
    notificationAlertMessage: string | undefined,
    isAddingCRForGroup: string | undefined,
    drawerSize: number,
    messageErrors: string[],
    lastActionResult: boolean | undefined
    headerHeight: number
}

const initialState: IAppState = {
    currentPage: "",
    tenantAccessDenied: false,
    notificationAlertMessage: undefined,
    isAddingCRForGroup: undefined,
    drawerSize: 0,
    messageErrors: [],
    lastActionResult: undefined,
    headerHeight: 0
};

export const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setHeaderHeight: (state, action: PayloadAction<number>) => {
            state.headerHeight = action.payload;
        },
        setLastActionResult: (state, action: PayloadAction<boolean | undefined>) => {
            state.lastActionResult = action.payload;
        },
        setMessageErrors: (state, action: PayloadAction<string[]>) => {
            state.messageErrors = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<string>) => {
            state.currentPage = action.payload
        },
        setTenantAccessDenied: (state, action: PayloadAction<boolean>) => {
            state.tenantAccessDenied = action.payload;
        },
        setNotificationAlertMessage: (state, action: PayloadAction<string | undefined>) => {
            state.notificationAlertMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher<RejectedAction>(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    const error = action.error as Error;
                    state.lastActionResult = false;

                    if (error.message === "Request failed with status code 403" ||
                        error.message === "Request failed with status code 403") {
                        deleteCookie("token");
                    }
                }
            )
            .addMatcher<FullfilledAction>(
                (action) => action.type.endsWith('_n/fulfilled'),
                (state) => {
                    state.lastActionResult = true;
                }
            )
    }
})


export const {
    setCurrentPage,
    setTenantAccessDenied,
    setNotificationAlertMessage,
    setMessageErrors,
    setLastActionResult,
    setHeaderHeight
} = appStateSlice.actions;
export default appStateSlice.reducer
