import {setDocumentTitle} from "ndr-designsystem";
import i18n from "../i18n";
import {TITLE_BASE} from "../utils";

const buildMainItemTitle = (components: string[], pathname: string, tenantName: string): void => {
    ["/tenants/"].forEach(searchPattern => {
        if (pathname.startsWith(searchPattern)) {
            components.push(tenantName)
        }
    })
}

const buildMainListItemTitle = (components: string[], pathname: string): void => {
    ["/admins", "/tenants"].forEach(searchPattern => {
        if (pathname === searchPattern) {
            components.push(i18n.t(`common:title.${pathname.replaceAll("/", "")}`))
        }
    })
    if (pathname === "/") {
        components.push(i18n.t("common:title.home"))
    }
}

const buildTitle = (pathname: string, tenantName?: string): void => {
    const components: string[] = []

    buildMainListItemTitle(components, pathname);
    buildMainItemTitle(components, pathname, tenantName!);

    setDocumentTitle(TITLE_BASE, ...components)
}

export default buildTitle;
