import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom"
import {ThemeProvider} from '@mui/material/styles';
import '@progress/kendo-theme-material/dist/all.css';
import './index.css';
import {Provider} from 'react-redux';
import App from './App';
import Theme from "./app/theme";
import './i18n';
import {store} from "./app/store";
import Config from "./app-config";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <Router basename={Config.basename}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
