import {createAsyncThunk} from "@reduxjs/toolkit";
import Admin from "../../../api/fixed/Admin";
import Api from "../../../api/Api";


export const fetchAdminUsers = createAsyncThunk('AdminList/fetch',
    async () => {
        const response = await Api.FetchAdminUsers();
        return response
    }
);

export const AddAdminUser = createAsyncThunk('AdminUser/add',
    async (details: Admin) => {
        const response = await Api.AddAdminUser(details)
        return { data: response.data, Admindetails: details }
    })

export const EditAdminUser = createAsyncThunk('AdminUser/edit',
    async (details: Admin) => {
        const response = await Api.EditAdminUser(details)
        const { inventoryItemId, revision, ...mainDetails } = details
        return { ...response.data, ...mainDetails }
    })

export const DeleteAdminUser = createAsyncThunk('AdminUser/delete',
    async (details: Admin) => {
        const response = await Api.DeleteAdminUser(details)
        return response.data.inventoryItemId
    })

export const ResetAdminUserPass = createAsyncThunk('AdminUser/ResetPass',
    async (Details: { UserID: string, pass: string }) => {
        await Api.ResetUserPass(Details)
        // eslint-disable-next-line no-console
        // no need tp return the response we are storing passwords only in the DB
    })