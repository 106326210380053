import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import TenantUser from "../../api/fixed/TenantUser";
import Admin from "../../api/fixed/Admin";
import AddInputs from "./AddInputs";

interface Props {
    currentData: Admin | TenantUser | undefined
    onClose: () => void
    onSubmit: (val: any) => void
    register: any,
    currentlyEditing: "add" | "edit" | "reset" | undefined
    formState: any,
    setValue: any
}

const AddDialog = ({currentData, onClose, onSubmit, register, currentlyEditing, formState, setValue }: Props): ReactElement => {
    const {t} = useTranslation("common");

    return <Dialog
        open={currentData !== undefined}
        fullWidth
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <form onSubmit={onSubmit}>
            {/* {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>} */}
            <DialogContent>
                <AddInputs register={register} currentlyEditing={currentlyEditing} formS={formState} setRegisterValue={setValue} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button type="submit" color="primary">
                    {t('submit')}
                </Button>
            </DialogActions>
        </form>
    </Dialog>
}

export default AddDialog