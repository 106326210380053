import axios, {AxiosError} from 'axios';
import Tenant, {TenantActivationRequest} from './fixed/Tenant';
import Admin, {AdminCrudResponse} from "./fixed/Admin"
import TenantUser from './fixed/TenantUser';
import Config from "../app-config";
import AccessResult from './fixed/LoginResult';
import {IEcertificate} from "./fixed/Certificate";


export default class Api {
    private static token: string | null = null;

    private static readonly DOMAIN: string = process.env.NODE_ENV === "development" ? "localhost" : (new URL(Config.auth.authority)).hostname;

    public static setToken(token: string | null): void {
        this.token = `Bearer ${token}`;
    }

    public static async HandleLoginAPI(username: any, password: any): Promise<AccessResult> {

        const params = new URLSearchParams();
        params.append("client_id", Config.auth.client_id);
        params.append("scope", Config.auth.scope);
        params.append("grant_type", "password");
        params.append("username", username);
        params.append("password", password);


        let apiRes: any;

        try {
            apiRes = await this.post('connect/token', params, true)
        } catch {
            apiRes = { status: 400 };
        }

        if (apiRes.status === 200) {
            this.setToken(apiRes.data.access_token);
            const accessResult = await this.checkIfUserHasAccess();
            if (accessResult === AccessResult.NO_ACCESS) return AccessResult.NO_ACCESS;

            if (this.DOMAIN === "localhost") {
                document.cookie = `token=${apiRes.data.access_token}; expires=Tue, 19 Jan 2038 04:14:07 GMT;path=/; secure;`;
            } else {
                document.cookie = `token=${apiRes.data.access_token}; expires=Tue, 19 Jan 2038 04:14:07 GMT; domain=${this.DOMAIN}; path=/; secure;`;
            }
            return AccessResult.SUCCESS;
        }
        return AccessResult.INCORRECT_DETAILS;
    }

    // eslint-disable-next-line no-unused-vars
    static async fetchTenantList(id: string | undefined, size: number): Promise<Tenant[]> {
        if (id)
            return this.get(`Tenants?size=${size}&lastid=${id}`)

        return this.get(`Tenants?size=${2 * size}`);
    }

    static async FetchTenantByID(id: string): Promise<Tenant> {
        return this.get(`Tenants/${id}`);
    }

    static async HandleTenantActivation(TenantActiveState: TenantActivationRequest): Promise<any> {
        return this.put(`Tenants/${TenantActiveState.id}/${TenantActiveState.revision}/${TenantActiveState.active}`, null)
    }

    static async HandleEditTenantDetails(newDetails: Tenant): Promise<any> {
        return this.put(`Tenants/${newDetails.inventoryItemId}`, newDetails)
    }

    static async HandleAddTenant(details: Tenant): Promise<any> {
        return this.post('tenants', details)
    }

    static async HandleAddTenantUser(details: any): Promise<any> {
        const { tenantId, ...rest } = details
        return this.post(`tenants/${tenantId}/users`, rest)
    }

    static async HandleEditTenantUser(details: TenantUser): Promise<any> {
        return this.put(`Persons/${details.inventoryItemId}`, details)
    }

    static async HandleDeleteTenantUser(details: TenantUser): Promise<any> {
        return this.delete(`Persons/${details.inventoryItemId}`, { inventoryItemId: details.inventoryItemId, revision: details.revision })
    }

    static async HandleImportCertificate(details: IEcertificate): Promise<any> {
        const formData = new FormData();
        if (details.CF)
            formData.append("File", details.CF)
        if (details.revision != null)
            formData.append("TenantRevision", details.revision.toString())
        if (details.password != null)
            formData.append('Password', details.password)
        if (details.publicKey != null)
            formData.append('PublicKey', details.publicKey)
        if (details.privateKey != null)
            formData.append('PrivateKey', details.privateKey)
        return this.put(`tenants/${details.tenantID}/certificates/${details.type}`, formData)
    }

    static async HandleFetchCertificate(details: any): Promise<any> {
        return this.get(`tenants/${details.tenantID}/certificate/${details.CT}`)
    }

    static async FetchAdminUsers(): Promise<Admin[]> {
        return this.get("AdminUsers")
    }

    static async AddAdminUser(details: Admin): Promise<AdminCrudResponse> {
        return this.post('AdminUsers', details)
    }

    static async EditAdminUser(details: Admin): Promise<AdminCrudResponse> {
        return this.put(`AdminUsers/${details.inventoryItemId}`, details)
    }

    static async DeleteAdminUser(details: Admin): Promise<AdminCrudResponse> {
        const { inventoryItemId, revision } = details
        return this.delete(`AdminUsers/${inventoryItemId}`, { revision, inventoryItemId })
    }

    static async ResetUserPass(Details: { UserID: string, pass: string }): Promise<any> {
        return this.put(`persons/${Details.UserID}/password`, {
            inventoryItemId: Details.UserID,
            password: Details.pass
        })
    }

    static async CallPhone(phoneNumber: string, message: string): Promise<void> {
        const config = this.buildConfig();
        let baseUrl = Config.callService.endpoint;
        if (baseUrl[baseUrl.length - 1] !== '/')
            baseUrl += '/';
        return axios.post(`${baseUrl}calls`, { phoneNumber, message }, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
    }

    public static async checkIfUserHasAccess(): Promise<AccessResult> {
        // TODO: find a better way to see if user has access to this tenant
        try {
            await this.get("AdminUsers");
        } catch (e) {
            const error = e as AxiosError;
            if (error.response?.status === 403)
                return AccessResult.NO_ACCESS;
            if (error.response?.status === 401)
                return AccessResult.EXPIRED;
            throw (e);
        }
        return AccessResult.SUCCESS;
    }

    private static buildConfig(): any {
        let config: any = null;
        if (this.token) {
            config = {
                headers: {
                    Authorization: this.token
                }
            }
        }
        return config

    }

    private static get(url: string, isAuthRequest: boolean = false): any {
        const config = this.buildConfig()
        const baseUrl = isAuthRequest ? Config.auth.authority : Config.domainAdminService.endpoint;
        return axios.get(baseUrl + url, config).then(resp => resp.data);
    }

    private static put(url: string, data: any, isAuthRequest: boolean = false): any {
        const config = this.buildConfig();
        const baseUrl = isAuthRequest ? Config.auth.authority : Config.domainAdminService.endpoint;
        return axios.put(baseUrl + url, data, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
    }

    private static delete(url: string, data: any, isAuthRequest: boolean = false): any {
        const config = this.buildConfig();
        const baseUrl = isAuthRequest ? Config.auth.authority : Config.domainAdminService.endpoint;
        return axios.delete(baseUrl + url, { ...config, data }).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
    }

    private static post(url: string, data: any, isAuthRequest: boolean = false): any {
        const config = this.buildConfig();
        const baseUrl = isAuthRequest ? Config.auth.authority : Config.domainAdminService.endpoint;
        return axios.post(baseUrl + url, data, config).then((resp): any => ({
            data: resp.data,
            status: resp.status
        }));
    }
}
