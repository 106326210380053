import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import TennantsReducer from "../features/Tenants/store/TenantsSlice"
import AdminsReducer from "../features/Admins/store/AdminsSlice"
import AppStateReducer from "../features/appStateSlice"

export const store = configureStore({
    reducer: {
        Tenants: TennantsReducer,
        Admins: AdminsReducer,
        appState: AppStateReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;