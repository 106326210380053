// Overwrite setting for DEV from local env settings if defined
const isDevEnv = process.env.NODE_ENV === "development";
const useLocalConfig = isDevEnv && process.env.REACT_APP_USE_LOCAL_CONFIGURATION === 'true'

const envConfig = {
    ...window,
    // Overwrite setting for DEV from local env settings if defined
    ...(useLocalConfig ? process.env : undefined),
} as any;

const Config =
{
    auth: {
        authority: envConfig.REACT_APP_AUTHN_AUTHORITY || "",
        client_id: envConfig.REACT_APP_AUTHN_CLIENTID || "",
        scope: envConfig.REACT_APP_AUTHN_SCOPE || ""
    },
    basename: envConfig.REACT_APP_SUB_DIRECTORY || "",
    domainAdminService: {
        endpoint: envConfig.REACT_APP_API_DOMAIN_ADMIN_SERVICE_URL || ""
    },
    callService: {
        endpoint: envConfig.REACT_APP_API_CALL_SERVICE_URL || "",
        message: envConfig.REACT_APP_CALL_SERVICE_MESSAGE || ""
    },
    language: envConfig.REACT_APP_LANGUAGE || undefined
}

// console.log('Used configuration: ', Config);

export default Config;
