import {FormControl, IconButton, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react'
import {FormState, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ContentCopy, LockReset} from "@mui/icons-material";
import {RandomPasswordGenerator} from "ndr-designsystem";
import useStyles from "./AddInputsStyle"

interface IEditProps {
    register: UseFormRegister<any>,
    formS: FormState<any>,
    setRegisterValue?: UseFormSetValue<any>
    currentlyEditing: "add" | "edit" | "reset" | undefined
}

const AddTenantUserInputs: React.FC<any> = (props: IEditProps) => {
    const { t } = useTranslation('common');
    const { register, formS, setRegisterValue, currentlyEditing } = props
    const [passwordState, setPasswordState] = useState<string>("")
    const classes = useStyles();

    const generateNewPass = (): void => {
        const pw = RandomPasswordGenerator()
        setPasswordState(pw)
        if (setRegisterValue)
            setRegisterValue("password", pw)
    }

    const onCopyClipboard = (): void => {
        navigator.clipboard.writeText(passwordState)
    }

    useEffect(() => {
        generateNewPass()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePassChange = (): void => {
        generateNewPass()
    }

    return (
        <div>
            {currentlyEditing !== "reset" && <>
                <FormControl className={classes.formControlStyle}>
                    <TextField label={t("name")}  {...register("name")} variant="standard" />
                </FormControl>
                <div className={classes.err}> {formS.errors && t(formS.errors.name?.message)}</div>
                <FormControl className={classes.formControlStyle}  >
                    <TextField label={t("email")} {...register("email")} variant="standard" />
                </FormControl>
                <div className={classes.err}>  {formS.errors && t(formS.errors.email?.message)}</div>
                <FormControl className={classes.formControlStyle}  >
                    <TextField label={t("phone")} {...register("phone")} variant="standard" />
                </FormControl>
                <div className={classes.err}>  {formS.errors && t(formS.errors.phone?.message)}</div>
            </>}
            {currentlyEditing !== "edit" && <>
                <FormControl className={classes.formControlStyle}>
                    <TextField value={passwordState} label={t("password")} {...register("password")} variant="standard" InputProps={{
                        startAdornment:
                            <IconButton onClick={handlePassChange}>
                                <LockReset />
                            </IconButton>,
                        endAdornment:
                            <IconButton onClick={onCopyClipboard}>
                                <ContentCopy />
                            </IconButton>
                    }} />
                </FormControl>

            </>
            }
        </div >
    )
}


AddTenantUserInputs.defaultProps = {
    setRegisterValue: undefined
}


export default AddTenantUserInputs