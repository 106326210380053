import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Admin from "../../../api/fixed/Admin";
import {AddAdminUser, DeleteAdminUser, EditAdminUser, fetchAdminUsers} from "./Thunks"


export interface AdminsState {
    admins: Admin[] | undefined
}

const initialState: AdminsState = {
    admins: undefined,
};

export const AdminsSlice = createSlice({
    name: "Admins",
    initialState,
    reducers: {
        setAdmins: (state, action: PayloadAction<Admin[] | undefined>) => {
            state.admins = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminUsers.fulfilled, (state, action) => {
                state.admins = action.payload
            })
            .addCase(AddAdminUser.fulfilled, (state, action) => {
                if (state.admins)
                    state.admins.push({ ...action.payload.data, ...action.payload.Admindetails })
            })
            .addCase(EditAdminUser.fulfilled, (state, action) => {
                if (state.admins) {
                    const NewAdmins = state.admins.map((item) =>
                        item.inventoryItemId === action.payload.inventoryItemId ? action.payload : item)
                    if (NewAdmins !== undefined)
                        state.admins = NewAdmins
                }
            })
            .addCase(DeleteAdminUser.fulfilled, (state, action) => {
                if (state.admins) {
                    const NewAdmins = state.admins.filter((item) => item.inventoryItemId !== action.payload)
                    if (NewAdmins !== undefined)
                        state.admins = NewAdmins
                }
            })
    }
})

export const { setAdmins } = AdminsSlice.actions;
export default AdminsSlice.reducer